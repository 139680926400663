import React, { useState } from 'react';

import SortControl from '../SortControl/SortControl';
import PaginationControl from '../PaginationControl/PaginationControl';
import FilterText from '../Filter/Filter';
import FilterList from '../FilterList/FilterList';

import styles from './ControlBar.module.scss';
import Transition from '../../furniture/Transition/Transition';

const ControlBar = ({
  currentPageLength,
  currentSort,
  filters,
  handleFilter,
  handleFilterGroup,
  handlePageLength,
  handleSort,
  sortOptions,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleShowFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <div className={styles.bar} id="list">
        <div className={styles.sortContainer}>
          <SortControl
            handleClick={handleSort}
            options={sortOptions}
            selectedSort={currentSort}
          />
        </div>
        <PaginationControl
          currentPageLength={currentPageLength}
          handleChange={handlePageLength}
        />
        <button className={styles.showFilter} onClick={handleShowFilterToggle}>
          Filters
        </button>
      </div>
      <Transition
        duration={200}
        entryType={''}
        exitType={''}
        show={showFilters}
      >
        <div className={styles.filters}>
          <div className={styles.text}>
            <FilterText handleChange={handleFilter} />
          </div>
          <div className={styles.list}>
            <FilterList filters={filters} handleChange={handleFilterGroup} />
          </div>
        </div>
      </Transition>
    </>
  );
};

export default ControlBar;
