import removeNode from './removeNode';

const filterCollections = (collections, filter, match, limit) => {
  return removeNode(collections)
    .filter(collection => {
      switch (filter) {
        case 'type':
          return collection.data.type === match;
        case 'tags':
          if (Array.isArray(match)) {
            return collection.data.tags && !!collection.data.tags.filter(tag => match.indexOf(tag.data.title) > -1).length;
          } else {
            return collection.data.tags && !!collection.data.tags.filter(tag => tag.data.title === match).length;
          }
        default:
          return collection;
      }
    }).slice(0, limit);
};

export default filterCollections;
