const clean = (obj, removeFalsey = false) =>
  Object.keys(obj)
    .filter(
      key =>
        obj[key] !== null &&
        typeof obj[key] !== 'undefined' &&
        (!removeFalsey || (removeFalsey && obj[key]))
    )
    .reduce((newObj, key) => Object.assign(newObj, { [key]: obj[key] }), {});

export default clean;
