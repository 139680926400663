// takes string as 1:1 and provides result as object
const getImageRatio = ratio => {
  if (!ratio || ratio.indexOf(':') === -1) {
    return {
      height: 1,
      width: 1,
    };
  }

  const value = ratio.split(':');

  return {
    width: value[0],
    height: value[1],
  };
};

export default getImageRatio;
