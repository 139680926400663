/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React, { useState } from 'react';

import unified from 'unified';
import markdown from 'remark-parse';
import { graphql } from 'gatsby';
import html from 'remark-html';
import filterCollections from '../../utils/data/filterCollections';

import Layout from '../../components/furniture/Layout/Layout';
import ItemList from '../../components/items/ItemList/ItemList';
import Share from '../../components/furniture/Share/Share';
import CollectionTitle from '../../components/collections/CollectionTitle/CollectionTitle';
import TagList from '../../components/tags/TagList/TagList';
import { useFirebase } from 'gatsby-plugin-firebase';
import generateTitle from '../../utils/generateTitle';
import queryString from 'query-string';
import getUserFromSlug from '../../utils/data/getUserFromSlug';
import CollectionCounters from '../../components/collections/CollectionCounters/CollectionCounters';
import getImageRatio from '../../utils/images/getImageRatio';
import removeEmptyRows from '../../utils/data/removeEmptyRows';
import getUserCollection from '../../utils/data/getUserCollection';
import getSortOptions from '../../utils/data/getInitialSort';

import addItem from './utils/addItem';
import removeItem from './utils/removeItem';
import CollectionList from '../../components/collections/CollectionList/CollectionList';
import CollectionActions from '../../components/collections/CollectionActions/CollectionActions';
import Modal from '../../components/furniture/Modal/Modal';
import Login from '../../components/auth/Login/Login';


const ownedActions = ['got'];

const CollectionPage = ({ data, location }) => {
  const {
    detail: { id: collectionId, data: collection },
    list: { edges: unfilteredList },
    allCollections: { edges: allCollections },
  } = data;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firebase, setFirebase] = useState();
  const [userData, setUserData] = useState({});
  const [compareUserData, setCompareUserData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const onSnapshot = (snapshot, collectionId) => {
    // only change the part of the object we have updated
    setUserData({
      ...(userData || {}),
      [collectionId]: (snapshot.data() || {})[collectionId],
    });
  };

  const onUpdateItem = async (type, action, itemId, collectionId) => {
    if (isLoggedIn) {
      const userId = firebase.auth().currentUser.uid;
      const db = firebase.firestore();
      const userRef = db.collection('userCollections').doc(userId);

      const unsubscribe = userRef.onSnapshot(snapshot =>
        onSnapshot(snapshot, collectionId)
      );

      if (type === 'add') {
        await addItem(
          db,
          userRef,
          action,
          itemId,
          collectionId,
          ownedActions
        );
      } else if (type === 'remove') {
        await removeItem(
          db,
          userRef,
          action,
          itemId,
          collectionId,
          ownedActions
        );
      }

      unsubscribe();
    } else {
      setShowModal(true);
    }
  };

  useFirebase(firebase => {
    const db = firebase.firestore();

    const getCompareUser = async () => {
      const params = queryString.parse(location.search);

      if (params.user) {
        const result = await getUserFromSlug(db, params.user);
        setCompareUserData(result);
      }
    };

    getCompareUser();

    setFirebase(firebase);

    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userData = await firebase
          .firestore()
          .collection('userCollections')
          .doc(user.uid)
          .get();
        setUserData({ ...userData.data() });
      }
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  // filter any blank entries
  const list = removeEmptyRows(unfilteredList);

  const sortOptions = getSortOptions(
    collection.sort_options,
    collection.initial_sort
  );
  const imageRatio = getImageRatio(collection.image_ratio);

  const pageTitle = generateTitle(collection.title, 'Collections');

  const userCollection = getUserCollection(userData, collectionId);
  const compareCollection = getUserCollection(compareUserData, collectionId);

  const compareUserName = compareUserData && compareUserData.userName;

  const modal = showModal ? (
    <Modal handleClose={() => setShowModal(false)} title={"Sign In"}>
      <>
        <Login />
        <button onClick={() => setShowModal(false)}>Cancel</button>
      </>
    </Modal>
  ) : null;

  return (
    <Layout
      location={location}
      shareImage={
        collection.image &&
        collection.image.localFiles[0].childImageSharp.fluid.src
      }
      pageTitle={pageTitle}
    >
      <CollectionTitle
        title={collection.title}
        subtitle={`The complete list`}
      />
      <TagList tags={collection.tags} />

      <div
        dangerouslySetInnerHTML={{
          __html: unified()
            .use(markdown)
            .use(html)
            .processSync(collection.body),
        }}
      />

      {collection.link && (
        <a href={collection.link}>&gt; Link to the official site</a>
      )}

      <CollectionCounters
        compareUserActions={compareCollection && compareCollection.actions}
        compareUserName={compareUserName}
        count={list.length}
        isLoggedIn={isLoggedIn}
        type={collection.type}
        userActions={userCollection && userCollection.actions}
      />

      <ItemList
        collectionId={collectionId}
        fields={collection.fields}
        filters={collection.filters}
        handleUpdateItem={onUpdateItem}
        hasImages={collection.has_images}
        imageRatio={imageRatio}
        initialSort={sortOptions.default}
        isLoggedIn={isLoggedIn}
        list={list}
        location={location}
        sortOptions={sortOptions.options}
        compareUserData={compareCollection}
        compareUserName={compareUserName}
        userData={isLoggedIn ? userCollection : undefined}
      />

      { isLoggedIn && <CollectionActions actions={collection.actions} handleClick={(action, type) => onUpdateItem(
        type,
        action,
        list.map(item => item.node.id),
        collectionId
      )} /> }

      <Share
        title={collection.title}
        url={`https://www.needswapgot.com/collections/${collection.slug}`}
      />

      <CollectionList
        collections={filterCollections(
          allCollections,
          'tags',
          (collection.tags || []).map(tag => tag.data.title),
          4
        )}
        title="More like this"
      />
      {modal}
    </Layout>
  );
};

export const pageQuery = graphql`
  query CollectionByPath($slug: String) {
    detail: airtable(table: { eq: "collections"}, data: { slug: { eq: $slug } }) {
      id
      data {
        slug
        title
        image {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 600, quality: 40) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        author
        body
        date
        type
        fields
        tags {
          data {
            title
            slug
          }
        }
        initial_sort
        sort_options
        filters  
        actions {
          id
          data {
            title
            slug
          }
        }
        owned_actions {
          id
        }
        has_images
        image_ratio
        work_in_progress
        link
      }
    }
    list: allAirtable(filter: { table: { eq: $slug } }) {
      edges {
        node {
          id
          data {
            title
            description
            image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 40) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            collects
            cover
            driver
            franchise
            issue
            minifigures
            number
            pieces
            rarity
            ref  
            release
            runtime
            season
            set
            team
            theme
            year
          }
        }
      }
    }
    allCollections: allAirtable(
      sort: { fields: [data___date], order: DESC }
      filter: { table: { eq: "collections" } }
    ) {
      edges {
        node {
          data {
            title
            slug
            image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 40) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            tags {
              data {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export default CollectionPage;
