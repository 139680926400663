import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import styles from './Pagination.module.scss';

const Pagination = ({ currentPage, handleChange, limit, totalPages, url }) => {
  if (totalPages === 1) {
    return null;
  }

  const getPages = (currentPage, limit, totalPages) => {
    const halfLimit = Math.round((limit || 4) / 2);
    const pages = [...Array(totalPages)];
    return pages.map((page, index) => index + 1).slice(
      currentPage > halfLimit ? currentPage - halfLimit : 0,
      currentPage < totalPages - halfLimit
        ? currentPage + halfLimit
        : totalPages
    );;
  };

  const pageLink = (page, text) => (
    <Link
      className={styles.link}
      onClick={() => handleChange(page)}
      to={`${url}?page=${page}#list`}
      dangerouslySetInnerHTML={{ __html: text}}
    />
  );

  return (
    <ul className={styles.wrapper}>
      <li className={styles.item}>
        {currentPage !== 1 ? (
          pageLink(1, "&laquo;")
        ) : (
          <div className={styles.disabled}>&laquo;</div>
        )}
      </li>
      <li className={styles.item}>
        {currentPage !== 1 ? (
          pageLink(currentPage - 1, "&lsaquo;")
        ) : (
          <div className={styles.disabled}>&lsaquo;</div>
        )}
      </li>
      {getPages(currentPage, limit, totalPages).map(page => {
        return (
          <li
            className={classNames(
              styles.item,
              currentPage === page && styles.current
            )}
            key={`page-${page}`}
          >
            {pageLink(page, page)}
          </li>
        );
      })}
      <li className={styles.item}>
        {currentPage !== totalPages ? (
          pageLink(currentPage + 1, "&rsaquo;")
        ) : (
          <div className={styles.disabled}>&rsaquo;</div>
        )}
      </li>
      <li className={styles.item}>
        {currentPage !== totalPages ? (
          pageLink(totalPages, "&raquo;")
        ) : (
          <div className={styles.disabled}>&raquo;</div>
        )}
      </li>
    </ul>
  );
};

export default Pagination;
