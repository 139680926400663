const getUserFromSlug = async (db, slug) => {
  const userRef = db.collection('users');
  const query = userRef.where('slug', '==', slug).limit(1);

  try {
    const result = await query.get();
    return result.docs[0].data();
  } catch (error) {
    console.log(error);
    return {};
  }
};

export default getUserFromSlug;
