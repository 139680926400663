const sort = (list, field, order) =>
  [...list].sort((a, b) => {

    let fieldA = a;
    let fieldB = b;

    if (field) {
      fieldA = (a.node || a).data[field];
      fieldB = (b.node || b).data[field];
    }

    if (typeof fieldA === 'string') {
      fieldA = fieldA.toLowerCase();
    }

    if (typeof fieldB === 'string') {
      fieldB = fieldB.toLowerCase();
    }

    if (order === 'desc') {
      if (fieldA > fieldB) {
        return -1;
      }
      if (fieldA < fieldB) {
        return 1;
      }
      return 0;
    } else {
      if (fieldA < fieldB) {
        return -1;
      }
      if (fieldA > fieldB) {
        return 1;
      }
      return 0;
    }
  });

export default sort;
