import React from 'react';
import classNames from 'classnames';

import styles from './ItemCompare.module.scss';

const ItemCompare = ({ userData, userName }) => {
  return (
    <>
      <div
        className={classNames(styles.wrapper, styles.compare)}
      >
        <span className={styles.action}>
          Got{' '}
          <span className={styles.userName}>({userName})</span>
        </span>
        <span className={styles.count}>
          {userName.actions && userData.actions.got
            ? userData.actions.got.count
            : 0}
        </span>
      </div>
    </>
  );
};

export default ItemCompare;
