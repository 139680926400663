import isOwnedAction from './isOwnedAction';

const createNewCollection = (action) => ({
  ownedCount: 0,
  items: {},
  actions: {
    [action]: createNewAction(),
  },
});

const createNewItem = action => ({
  owned: false,
  actions: {
    [action]: {
      count: 0,
      detail: [], // condition, sale
    },
  },
});

const createNewAction = () => ({
  count: 0,
  uniqueCount: 0,
});

const addItem = async (db, userRef, action, itemIds, collectionId, ownedActions) => {
  const user = await userRef.get();

  const collections = user.data() || {};

  // populate the collection if it doesn't already exist
  if (!collections[collectionId]) {
    collections[collectionId] = createNewCollection(action);
  }

  // make a copy
  const collection = { ...collections[collectionId] };

  (Array.isArray(itemIds) ? itemIds : [itemIds]).forEach(itemId => {
    // populate the item if it doesn't already exist
    if (!collection.items[itemId]) {
      collection.items[itemId] = createNewItem(action);
    }

    // populate the action if it doesn't already exist
    if (!collection.actions[action]) {
      collection.actions[action] = createNewAction();
    }

    // populate the item action if it doesn't already exist
    if (!collection.items[itemId].actions[action]) {
      collection.items[itemId].actions[action] = {
        count: 0,
      };
    }

    // check if one is already owned, and mark as owned if it isn't
    if (isOwnedAction(action, ownedActions) && !collection.items[itemId].owned) {
      collection.ownedCount++;
      collection.items[itemId].owned = true;
    }

    // check if this action has already been uniquely counted
    if (collection.items[itemId].actions[action].count === 0) {
      collection.actions[action].uniqueCount++;
    }

    // add one to the action count on item and collection
    collection.items[itemId].actions[action].count++;
    collection.actions[action].count++;

    if (action === 'got' && collection.items[itemId].actions['need'] && collection.items[itemId].actions['need'].count === 1) {
      collection.items[itemId].actions['need'].count = 0;
      collection.actions['need'].count--;
      collection.actions['need'].uniqueCount--;
    }
  });

  await userRef.set(
    {
      [collectionId]: collection,
    },
    { merge: true }
  );
};

export default addItem;
