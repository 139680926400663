import React from 'react';
import { Link } from 'gatsby';

import styles from './CollectionCounters.module.scss';
import StatsProgress from '../../items/Progress/Progress';

const actions = [{
  id: 'need',
  title: 'Need'
}, {
  id: 'got',
  title: 'Got',
}];

const CollectionCounters = ({
  compareUserName,
  compareUserActions,
  count,
  type,
  userActions,
}) => (
  <>
    <div className={styles.wrapper}>
      <StatsProgress
        count={count}
        totalCount={count}
        title={type}
        type={'summary'}
      />
      { /* <StatsProgress
        count={30}
        totalCount={30}
        title={'collectors'}
        type={'summary'}
      />
      {actions.map(action => (
        <StatsProgress
          count={
            (userActions &&
              userActions[action.id] &&
              userActions[action.id].uniqueCount) ||
            0
          }
          totalCount={count}
          title={action.title}
          type={action.id}
        />
      ))}
      {false && compareUserName &&
        actions.map(action => (
          <StatsProgress
            count={
              (compareUserActions &&
                compareUserActions[action.id] &&
                compareUserActions[action.id].uniqueCount) ||
              0
            }
            totalCount={count}
            title={compareUserName}
            type={action.id}
          />
        ))} */}
    </div>
  </>
);

export default CollectionCounters;
