import React, { useState } from 'react';

import sort from '../../../utils/data/sort';

import styles from './FilterList.module.scss';

const FilterList = ({ filters, handleChange }) => {
  const [userFilters, setUserFilters] = useState({});

  const handleClick = event => {
    const updatedFilters = userFilters;
    const target = event.target;
    const filter = target.getAttribute('data-filter');
    const item = target.getAttribute('data-item');

    if (target.checked) {
      if (updatedFilters[filter]) {
        updatedFilters[filter].push(item);
      } else {
        updatedFilters[filter] = [item];
      }
    } else {
      updatedFilters[filter] = updatedFilters[filter].filter(node => node !== item);

      if (updatedFilters[filter].length === 0) {
        delete updatedFilters[filter];
      }
    }

    handleChange(updatedFilters);
    setUserFilters(updatedFilters);
  };

  return (
    <div className={styles.wrapper}>
      {sort(Object.keys(filters)).map(filter => (
        <div key={filter}>
          <h2 className={styles.title}>{filter}</h2>
          <ul className={styles.list}>
            {sort(Object.keys(filters[filter])).map(item => (
              <li className={styles.filter} key={item}>
                <input
                  className={styles.checkbox}
                  data-filter={filter}
                  data-item={item}
                  id={`${filter}-${item}`}
                  onClick={handleClick}
                  type="checkbox"
                />
                <label className={styles.item} htmlFor={`${filter}-${item}`}>
                  {item}
                </label>{' '}
                <span className={styles.count}>({filters[filter][item]})</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FilterList;
