const getSortOptions = (fields, defaultSort) => {
  const sortOptions = fields && fields.split(',').map(field => field.trim());
  const [defaultField, defaultOrder] =
    defaultSort && defaultSort.indexOf(',') > -1
      ? defaultSort.split(',')
      : ['title', 'asc'];

  return {
    options: sortOptions,
    default: {
      field: defaultField.trim(),
      order: defaultOrder.trim(),
    },
  };
};

export default getSortOptions;
