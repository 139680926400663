import React from 'react';
import styles from './Filter.module.scss';

const FilterText = ({ handleChange }) => {
  return (
    <div className={styles.filter}>
      <span className={styles.title}>Filter by text</span>
      <input
        className={styles.input}
        name="filter"
        onKeyUp={event => handleChange(event.target.value)}
      />
    </div>
  );
};

export default FilterText;
