import React from 'react';
import classNames from 'classnames';

import styles from './ItemTile.module.scss';
import ItemActions from '../ItemActions/ItemActions';
import ItemCount from '../ItemCount/ItemCount';
import ItemCompare from '../ItemCompare/ItemCompare';

const ItemTile = ({
  actions,
  compareUserData,
  compareUserName,
  collectionId,
  handleUpdateItem,
  isLoggedIn,
  itemId,
  fields,
  imageRatio,
  item,
  userData = {},
}) => {
  const image =
    item.image && item.image.localFiles[0].childImageSharp.fluid.src;

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.imageWrapper,
          userData.owned && styles.owned
        )}
        style={{
          paddingBottom: `calc(${imageRatio.height}%/(${imageRatio.width}/100))`,
        }}
      >
        {image ? (
          <img
            alt={item.title}
            className={styles.image}
            loading="lazy"
            src={image}
          />
        ) : (
          <div className={styles.placeholder} />
        )}
      </div>
      <h1 className={styles.title}>{item.title}</h1>
      {fields && (
        <ul className={styles.list}>
          {fields.split(',').map(field => {
            let fieldName = field.trim().toLowerCase();
            let value = item[fieldName];

            if (value === 9999999) {
              value = 'TBA';
            }

            return (
              <li className={styles.field} key={`${item.id}_${fieldName}`}>
                <strong>{field}</strong> - {value}
              </li>
            );
          })}
        </ul>
      )}
      {compareUserName && (
        <ItemCompare
          actions={actions}
          userData={compareUserData}
          userName={compareUserName}
        />
      )}
      { isLoggedIn && <div className={styles.actions}>
        <ItemActions
          actions={actions}
          handleUpdateItem={handleUpdateItem}
          itemId={itemId}
          collectionId={collectionId}
          userData={userData}
        />
      </div> }
    </div>
  );
};

export default ItemTile;
