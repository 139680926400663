import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import queryString from 'query-string';

import ItemRow from '../ItemRow/ItemRow';
import ItemTile from '../ItemTile/ItemTile';

import styles from './ItemList.module.scss';
import ControlBar from '../../controls/ControlBar/ControlBar';
import sort from './../../../utils/data/sort';
import Pagination from '../../controls/Pagination/Pagination';

const ItemList = ({
  actions,
  collectionId,
  compareUserData,
  compareUserName,
  filters,
  fields,
  handleUpdateItem,
  hasImages,
  imageRatio,
  initialSort,
  isLoggedIn,
  list,
  location,
  sortOptions,
  userData,
}) => {
  const params = queryString.parse(location.search);
  const [currentList, setCurrentList] = useState(list);
  const [currentPaginatedList, setCurrentPaginatedList] = useState(list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentFilterGroup, setCurrentFilterGroup] = useState({});
  const [currentSort, setCurrentSort] = useState(initialSort);
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [currentPageLength, setCurrentPageLength] = useState(12);

  const updateList = (list, update) => {
    list = filterList(list, update.filter || currentFilter);
    list = filterGroupList(list, update.filterGroup || currentFilterGroup);
    list = sort(
      list,
      update.field || currentSort.field,
      update.order || currentSort.order
    );
    setCurrentList(list);
    list = paginateList(
      list,
      update.page || currentPage,
      update.pageLength ||
        currentPageLength
    );

    setCurrentPaginatedList(list);
  };

  const paginateList = (list, currentPage, currentPageLength) => {
    const start = (currentPage - 1) * currentPageLength;
    const end = currentPage * currentPageLength;
    return list.slice(start, end);
  };

  const filterGroupList = (list, filter) => {
    const keys = Object.keys(filter);

    if (!keys || !keys.length) {
      return list;
    }

    let filteredList = keys.map(key => {
      const result = filter[key].map(text => {
        return filterList(list, text, [key]);
      });
      return [].concat.apply([], result);
    });

    return [].concat.apply([], filteredList);
  };

  const filterList = (list, filter, key) => {
    const includeKeys = key || ['title'];

    if (Array.isArray(filter)) {
      filter = filter[0];
    }

    let including = filter.indexOf('!') !== 0;

    // make our filter case insensitive
    filter = filter.toLowerCase().replace(/^!/, '');

    if (filter.length > 0) {
      let result = [];

      list.forEach(item => {
        let isFound = false;

        Object.keys(item.node.data).some(key => {
          if (includeKeys.indexOf(key) > -1) {
            let itemValue = item.node.data[key].toString().toLowerCase();
            if (itemValue.indexOf(filter) > -1) {
              if (including) {
                result.push(item);
              }
              isFound = true;
              return true;
            }
          }
          return false;
        });
        // if we're not including and there were no matches
        if (!isFound && !including) {
          result.push(item);
        }
      });

      return result;
    }
    return list;
  };

  const handlePageLength = pageLength => {
    setCurrentPage(1);
    localStorage.setItem('pageLength', pageLength.value);
    setCurrentPageLength(pageLength.value);
    updateList(list, { page: 1, pageLength: pageLength.value });
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    updateList(list, { page });
  };

  const handleSort = (field, order) => {
    setCurrentSort({ field, order });
    updateList(list, { field, order });
  };

  const handleFilterGroup = filterGroup => {
    setCurrentFilterGroup(filterGroup);
    updateList(list, { filterGroup });
  };

  const handleFilter = filter => {
    setCurrentFilter(filter);
    updateList(list, { filter });
  };

  const ItemComponent = hasImages ? ItemTile : ItemRow;

  const filterCount = {};
  ((filters && filters.split(',')) || []).map(filter => {
    filterCount[filter] = {};
    list.some(({ node: { data: item } }) => {
      if (item[filter]) {
        if (filterCount[filter][item[filter]]) {
          filterCount[filter][item[filter]]++;
        } else {
          filterCount[filter][item[filter]] = 1;
        }
      }
    });
  });

  useEffect(() => {
    if (initialSort) {
      handleSort(initialSort.field, initialSort.order);
    }
  }, []);

  useEffect(() => {
    let pageLength = params.length || localStorage.getItem('pageLength') || 12;

    if (isNaN(parseInt(pageLength, 10))) {
      pageLength = 12;
    }

    handlePageLength({ value: pageLength });
  }, []);

  return (
    <>
      <ControlBar
          filters={filterCount}
          handleSort={handleSort}
          handleFilter={handleFilter}
          handleFilterGroup={handleFilterGroup}
          handlePageLength={handlePageLength}
          currentSort={currentSort}
          currentPageLength={currentPageLength}
          sortOptions={sortOptions}
        />
      <div className={styles.wrapper}>
        <div className={classNames(styles.container, hasImages && styles.tiles)}>
          {currentPaginatedList.length ? (
            currentPaginatedList.map(({ node: { id: itemId, data: item } }) => {
              return (
                <div className={styles.item} key={itemId}>
                  <ItemComponent
                    actions={actions}
                    collectionId={collectionId}
                    compareUserData={
                      (compareUserData &&
                        compareUserData.items &&
                        compareUserData.items[itemId]) || { count: 0 }
                    }
                    compareUserName={compareUserName}
                    handleUpdateItem={handleUpdateItem}
                    imageRatio={imageRatio}
                    isLoggedIn={isLoggedIn}
                    itemId={itemId}
                    item={item}
                    fields={fields}
                    userData={
                      userData && userData.items && userData.items[itemId]
                    }
                  />
                </div>
              );
            })
          ) : (
            <div className={styles.empty}>
              <h1 className={styles.emptyTitle}>No results found.</h1>
              <p>
                <a href={location.pathname}>Reset filters</a>
              </p>
            </div>
          )}
        </div>
      </div>
      <Pagination
        handleChange={handlePageChange}
        totalPages={Math.ceil(currentList.length / currentPageLength)}
        currentPage={currentPage}
        url={location.pathname}
      />
    </>
  );
};

ItemList.propTypes = {};

export default ItemList;
