import React from 'react';
import classNames from 'classnames';

import styles from './Share.module.scss';

const networks = [
  {
    appId: '589329394605574',
    name: 'Facebook',
    url:
      'https://www.facebook.com/dialog/share?app_id={2}&display=popup&href={0}',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/intent/tweet?text={1} - {0} #needswapgot',
  },
  {
    name: 'Reddit',
    url: 'https://www.reddit.com/submit?url={0}&title={1}',
  },
  {
    name: 'Pinterest',
    url:
      'https://www.pinterest.com/pin/create/button/?description={1}&media=&url={0}',
  }];

export const replace = (stringToFormat, ...args) => {
  return stringToFormat.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match;
  });
};

const Share = ({ title, url }) => (
  <div className={styles.share}>
    {networks &&
      networks.map((network) => (
        <a
          className={classNames(
            styles.link,
            styles[network.name.toLowerCase()]
          )}
          key={network.name}
          href={replace(network.url, url, title, network.appId)}
        ><span className={styles.text}>Share to {network.name}</span></a>
      ))}
  </div>
);

export default Share;
