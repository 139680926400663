import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Progress.module.scss';

const StatsProgress = ({ action, count, title, totalCount, type }) => {
  let getProgress = (statCount, totalCount) => {
    return (100 / totalCount * statCount).toFixed();
  };

  return (
    <div
      className={classNames(
        styles.progress
      )}
      data-progress={getProgress(count, totalCount)}
    >
      <div className={classNames(styles.wrapper, styles[type])}>
        <div className={styles.circle}>
          <div className={classNames(styles.circleMask, styles.circleMaskFull)}>
            <div className={styles.circleFill} />
          </div>
          <div className={styles.circleMask}>
            <div className={styles.circleFill} />
            <div className={classNames(styles.circleFill, styles.circleFillFix)} />
          </div>
          <div className={styles.inset}>
            <div className={styles.info}>
              <span className={styles.count}>{count}</span>
              <span className={styles.title}>{title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatsProgress.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  totalCount: PropTypes.number,
};

StatsProgress.defaultProps = {
  count: 0,
  totalCount: 0,
};

export default StatsProgress;
