import React, { useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import styles from './SortControl.module.scss';

const SortControl = ({ handleClick, options, selectedSort }) => {
  const [sortBy, setSortBy] = useState(selectedSort.field);
  const [sortDirection, setSortDirection] = useState(selectedSort.order);

  const handleSortByChange = option => {
    setSortBy(option.value);
    handleClick(option.value, sortDirection);
  };

  const toggleSortDirection = () => {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(direction);
    handleClick(sortBy, direction);
  };

  const formatSortOption = option => ({
    label: option.trim(),
    value: option.trim().toLowerCase(),
  });

  const sortOptions = (options || []).map(option => formatSortOption(option));

  return (
    <div className={styles.wrapper}>
      <Select
        className={styles.field}
        classNamePrefix="select"
        onChange={handleSortByChange}
        options={sortOptions}
        value={formatSortOption(selectedSort.field)}
      />
      <button
        className={classNames(styles.direction, styles[sortDirection])}
        onClick={toggleSortDirection}
      >
        {sortDirection}
      </button>
    </div>
  );
};

export default SortControl;
