import React from 'react';
import classNames from 'classnames';

import styles from '../ItemActions/ItemActions.module.scss';

const ItemActions = ({
  compareUserData = {},
  compareUserName,
  handleUpdateItem,
  itemId,
  collectionId,
  actions,
  userData = {},
}) => {
  const hasUserMarkedItemAsNeeded =
    userData.actions && userData.actions.need && userData.actions.need.count > 0;
  const hasUserGotItem =
    userData.actions && userData.actions.got && userData.actions.got.count > 0;
  const userGotCount = hasUserGotItem ? userData.actions.got.count : 0;

  const hasComparedUserMarkedItemAsNeeded =
    compareUserData.actions && compareUserData.actions.need && compareUserData.actions.need.count > 0;
  const hasComparedUserGotItem =
    compareUserData.actions && compareUserData.actions.got && compareUserData.actions.got.count > 0;
  const comparedUserGotCount = hasComparedUserGotItem ? compareUserData.actions.got.count : 0;

  return (
    <>
      {compareUserName &&
        <>
          <div
            className={classNames(styles.wrapper, styles.compare)}
          >
            <span className={styles.action}>
              Need{' '}
              <span className={styles.userName}>({compareUserName})</span>
            </span>
            <span className={styles.count}>
              {hasComparedUserMarkedItemAsNeeded}
            </span>
          </div>
          <div
            className={classNames(styles.wrapper, styles.compare)}
          >
            <span className={styles.action}>
              Got{' '}
              <span className={styles.userName}>({compareUserName})</span>
            </span>
            <span className={styles.count}>{comparedUserGotCount}</span>
          </div>
        </>
      }

      <div className={styles.wrapper}>
        <button
          className={classNames(
            styles.button,
            styles.need,
            hasUserMarkedItemAsNeeded && styles.marked,
            hasUserGotItem &&
              styles.hidden
          )}
          onClick={() => hasUserMarkedItemAsNeeded ? handleUpdateItem('remove', 'need', itemId, collectionId) : handleUpdateItem('add', 'need', itemId, collectionId)}
        >
          <span className={styles.action}>Need</span>
        </button>
        <button
          className={classNames(styles.button, styles.got)}
          onClick={() => {
            handleUpdateItem('add', 'got', itemId, collectionId);
          }}
        >
          <span className={styles.action}>Got</span>
          <span className={styles.count}>
            {userGotCount}
          </span>
        </button>
        <button
          className={classNames(
            styles.button,
            styles.remove,
            !hasUserGotItem &&
              styles.hidden
          )}
          onClick={() =>
            handleUpdateItem('remove', 'got', itemId, collectionId)
          }
        >
          <span className={styles.label}>Remove</span>
        </button>
      </div>
    </>
  );
};

export default ItemActions;
