import React from 'react';
import Select from 'react-select';

import styles from './PaginationControl.module.scss';

const PaginationControl = ({ currentPageLength, handleChange }) => {
  const options = [{
    label: 12,
    value: 12,
  }, {
    label: 24,
    value: 24,
  }, {
    label: 48,
    value: 48,
  }, {
    label: 96,
    value: 96,
  }, {
    label: 144,
    value: 144,
  }, {
    label: 192,
    value: 192,
  }];

  return <Select
    className={styles.field}
    classNamePrefix="select"
    onChange={handleChange}
    options={options}
    value={{ label: currentPageLength, value: currentPageLength}}
  />
};

export default PaginationControl;
