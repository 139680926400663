import React from 'react';
import classNames from 'classnames';

import styles from '../CollectionActions/CollectionActions.module.scss';

const CollectionActions = ({ handleClick }) => {
  return (
    <section className={styles.wrapper}>
      <h1 className={styles.title}>Batch update</h1>

      <div className={styles.item}>
        <button className={classNames(styles.button, styles.add)} onClick={() => handleClick('got', 'add')}>
          Add 1 to all
        </button>
        <button className={classNames(styles.button, styles.remove)} onClick={() => handleClick('got', 'remove')}>Remove 1 from all</button>
      </div>
    </section>
  );
};

export default CollectionActions;
