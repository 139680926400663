import isOwnedAction from './isOwnedAction';

const doesItemContainOtherOwnedActions = (action, item, ownedActions) => {
  // get all actions that are not this one
  const { [action]: discard, ...actions } = item;
  return ownedActions.some(
    action => actions[action.id] && actions[action.id].count > 0
  );
};

const removeItem = async (db, userRef, action, itemIds, collectionId, ownedActions) => {
  const user = await userRef.get();

  const collections = user.data() || {};

  if (!collections[collectionId]) {
    return;
  }

  // make a copy
  const collection = { ...collections[collectionId] };

  (Array.isArray(itemIds) ? itemIds : [itemIds]).forEach(itemId => {
    let updatedCount = false;

    // subtract one to the action count on item and collection
    if (collection.items[itemId].actions[action].count > 0) {
      collection.items[itemId].actions[action].count--;

      if (collection.actions[action].count > 0) {
        collection.actions[action].count--;
      }

      // flag we have updated the count
      updatedCount = true;
    }

    // only reduce the unique count if this action is now at 0
    if (updatedCount && collection.items[itemId].actions[action].count === 0) {
      collection.actions[action].uniqueCount--;
    }

    // check if one is already owned, and mark as owned if it isn't
    if (
      updatedCount &&
      isOwnedAction(action, ownedActions) &&
      collection.items[itemId].actions[action].count === 0 &&
      !doesItemContainOtherOwnedActions(
        action,
        collection.items[itemId].actions,
        ownedActions
      )
    ) {
      collection.ownedCount--;
      collection.items[itemId].owned = false;
    }
  });

  await userRef.set(
    {
      [collectionId]: collection,
    },
    { merge: true }
  );
};

export default removeItem;
